<template>
  <!--学习园地：课程精选-->
  <div class="page-box">
    <el-tabs v-model="activeName" @tab-click="getCourse">
      <el-tab-pane :label="$t('Free_good_lessons')" name="first" >
        <!--暂无数据-->
        <NoData v-if="freeCouseNoData" />
        <!--有数据时显示-->
        <el-row v-else :gutter="30">
          <el-col :span="6" v-for="item in freeCouse" :key="item.id">
            <div class="main-box" @click="ToCourseDetails(item.id)">
              <!--图片栏-->
              <div class="img">
                <!--图片-->
                <img :src="item.courseCover" alt="" />
              </div>
              <!--内容栏-->
              <div class="content">
                <!--标题-->
                <div class="title">
                  {{ item.courseName }}
                </div>
                <!--金额、学习人数-->
                <div class="text">
                  <span class="price" style="font-size: 18px">{{ $t('free') }}</span>
                  <span class="count">{{ item.count }}{{ $t('people_study') }}</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>

      <el-tab-pane :label="$t('High_quality_selection')" name="second">
        <!--暂无数据-->
        <NoData v-if="chargeCourseNoData" />
        <!--有数据时显示-->
        <el-row v-else :gutter="30">
          <el-col :span="6" v-for="item in chargeCourse" :key="item.id">
            <div class="main-box" @click="ToCourseDetails(item.id)">
              <!--图片栏-->
              <div class="img">
                <!--图片-->
                <img :src="item.courseCover" alt="" />
              </div>
              <!--内容栏-->
              <div class="content">
                <!--标题-->
                <div class="title">
                  {{ item.courseName }}
                </div>
                <!--金额、学习人数-->
                <div class="text">
                  <span class="price">
                    <span class="unit">￥</span>
                    {{ item.coursePrice }}
                  </span>
                  <span class="count">{{ item.count }}{{ $t('people_study') }}</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import API from '@/api';
import NoData from '@/components/NoData'; // 暂无数据组件
// import cookies from '@/utils/cookies'
export default {
  components: { NoData },
  data() {
    return {
      activeName: 'first',
      token: '',
      freeCouse: [], // 免费好课
      freeCouseNoData: false, // // 免费好课，是否展示无数据图片
      chargeCourse: [], // 优质精选
      chargeCourseNoData: false, // // 优质精选，是否展示无数据图片
    };
  },
  created() {
    // 获取课程封面，标题
    this.getCourse();
  },
  computed: {
    isChineseLocale() {
      return localStorage.getItem('locale') === 'en';
    }
  },
  methods: {
    // 获取课程封面，标题
    getCourse() {
      API.course()
          .then((res) => {
            if (res.message.success === true) {
              this.freeCouse = res.message.data.freeCourse;
              if (this.freeCouse.length === 0) {
                this.freeCouseNoData = true;
              }
              this.chargeCourse = res.message.data.chargeCourse;
              if (this.chargeCourse.length === 0) {
                this.chargeCourseNoData = true;
              }
            }
          })
          .catch(() => {
            this.freeCouseNoData = true;
            this.chargeCourseNoData = true;
          });
    },
    // 查看课程详情页
    ToCourseDetails(id) {
      console.log(this.isChineseLocale)
      window.open(`${process.env.VUE_APP_PAY}?id=${id}&locale=${this.isChineseLocale}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-box {
  height: 100%;
  // tab页
  ::v-deep .el-tabs {
    height: 100%;
  }
  // tab页-内容
  ::v-deep .el-tabs__content {
    height: calc(100% - 55px);
    overflow: auto;
    margin-right: -40px;
  }
  ::v-deep .el-tab-pane {
    width: 1630px;
    margin: 0px auto;
    padding-right: 40px;
  }
}
.main-box {
  width: 375px;
  height: 281px;
  background: #fff;
  border-radius: 2px;
  margin: 0 0 30px 0;
  cursor: pointer;
  overflow: hidden;
  line-height: 1;
  // 图片栏
  .img {
    width: 100%;
    height: 210px;
    background: #e3e3e3;
    position: relative;
    // 图片
    img {
      width: 100%;
      height: 100%;
    }
  }
  // 内容区域
  .content {
    width: 100%;
    padding: 15px 20px;
    overflow: hidden;
    // 标题
    .title {
      font-size: 14px;
      color: #000000;
      height: 16px;
      line-height: 16px;
      overflow: hidden;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      margin-bottom: 10px;
    }

    //金额、学习人数
    .text {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      //单位
      .unit {
        font-size: 16px;
        font-weight: bold;
        color: #f3a000;
      }
      // 金额
      .price {
        font-size: 20px;
        font-weight: bold;
        color: #f3a000;
      }
      // 学习人数
      .count {
        /*font-size: 16px;*/
        color: #999999;
      }
    }
  }
}
</style>
